import client, { authHeaders, responseBody } from "./client";
import {
  FilterQuery as BookingsSearchQuery,
  BookingGroups,
  Booking,
  BookingPositionDetails,
} from "@/models/your-bookings";

export default {
  getBookings: (params: BookingsSearchQuery) =>
    client
      .get("/v2/bookings", authHeaders({ params }))
      .then<BookingGroups[]>(responseBody),
  getBookingDetails: (id: number | string) =>
    client
      .get(`/v2/bookings/${id}/details`, authHeaders())
      .then<Booking>(responseBody),
  cancelBooking: (id: string | number, reason: string) =>
    client
      .post(`/v2/cancel-booking/${id}`, { reason }, authHeaders())
      .then<Booking>(responseBody),
  confirmBooking: (id: string | number) =>
    client
      .post(`/v2/confirm-booking/${id}`, {}, authHeaders())
      .then<Booking>(responseBody),
  updateJobStatus: (id: string | number, status: string) =>
      client
        .post(`/v2/bookings/${id}/job-status`, { status }, authHeaders())
        .then<Booking>(responseBody),
};
