import { defineStore, storeToRefs } from 'pinia'
import API from '@/api'
import { useApiErrors } from '@/store/api-errors'
import { useApiRequests } from '@/store/api-requests'
import { FilterQuery } from '@/models/your-bookings'

type StoreType = {
    filter: FilterQuery;
}

export const useBookingStore = defineStore('booking', {
    state: (): StoreType => ({
        filter: new FilterQuery(),
    }),
    actions: {
        getBookings() {
            return API.getBookings(this.filter)
        },
        updateFilter(data?: Record<string, unknown>) {
            this.filter = new FilterQuery(data)
        },
        getBookingDetails(id: number) {
            return API.getBookingDetails(id)
        },
        confirmBooking(id: number | string) {
            return API.confirmBooking(id)
        },
        cancelBooking(id: number, reason: string) {
            return API.cancelBooking(id, reason)
        },
        updateJobStatus(id: number, status: string) {
            return API.updateJobStatus(id, status)
        },
    },
})

export function useBookings() {
    const store = useBookingStore()

    const uri = '/bookings'
    const { error, errors } = useApiErrors(uri)
    const { isPending } = useApiRequests(uri)

    const { filter } = storeToRefs(store)

    return {
        filter,
        error,
        errors,
        isPending,
        getBookings: store.getBookings,
        updateFilter: store.updateFilter,
    }
}

export function useBooking(id: number) {
    const store = useBookingStore()

    const uri = `/booking-details/${id}`
    const { isPending } = useApiRequests(uri)

    return {
        isPending,
        getBooking: () => store.getBookingDetails(id),
    }
}


export function useConfirmBooking(id: number | string) {
    const store = useBookingStore()

    const uri = `/confirm-booking/${id}`
    const { error } = useApiErrors(uri)
    const { isPending } = useApiRequests(uri)

    return {
        error,
        isPending,
        confirmBooking: () => store.confirmBooking(id),
    }
}


export function useCancelBooking(id: number) {
    const store = useBookingStore()

    const uri = `/cancel-booking/${id}`
    const { error } = useApiErrors(uri)
    const { isPending } = useApiRequests(uri)

    return {
        error,
        isPending,
        cancelBooking: (reason: string) => store.cancelBooking(id, reason),
    }
}

export function useUpdateJobStatus(id: number) {
    const store = useBookingStore()

    const uri = `/bookings/${id}/job-status`
    const { error } = useApiErrors(uri)
    const { isPending } = useApiRequests(uri)

    return {
        error,
        isPending,
        updateJobStatus: (status: string) => store.updateJobStatus(id, status),
    }
}