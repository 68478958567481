import { useApiErrors } from '@/store/api-errors'
import { useApiRequests } from '@/store/api-requests'
import client, { responseBody } from '@/api/client'
import type { Faq } from '@/models/faq'

export function useFaq() {
    const { error } = useApiErrors('/faq')
    const { isPending } = useApiRequests('/faq')

    return {
        getFaq: () => client.get('/v2/faq').then<Faq[]>(r => r.data),
        isPending,
        error,
    }
}

